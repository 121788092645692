<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import { Table, Message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '../../../command/netTool'

export default {
  name: 'ruralDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        farmCountryImpressDetailVOS: [],
        farmTravelRouteDayVOS: [],
      },
      shopDetail: {
        activeType: '',
        shopId: '',
        description: '',
        shopName: '',
        imgUrl: '',
        shopLabel: [],
      },
      activeTypeObj: {
        1: '景点',
        2: '民宿农舍',
        3: '农家乐',
        4: '农事体验-采摘',
        5: '农事体验-垂钓',
      },
      mainBodyTable: [],
      idData: null,
      typeArrData: [],
      activeType: null,
    }
  },
  watch: {
    'shopDetail.activeType'(a, b) {
      if (a) {
        this.onSearchDianpu()
      }
    },
  },
  mounted() {
    this.idData = this.$route.query.id
    if (this.idData) {
      api.command.getDetail.call(this, {
        url: `/market/farmCountryImpress/get?id=${this.idData}`,
      })
    } else {
      postAction('/market/farmCountryImpress/add').then((result) => {
        this.idData = result.data.id
      })
    }
  },
  methods: {
    getTypeDdata(index) {
      const { shopId, activeType } = this.shopDetail
      let that = this
      return new Promise((resolve, reject) => {
        getAction(`/api/base/farmLabelData/selectLabelForImpress?shopId=${shopId}&typeCode=${activeType}`).then(
          (result) => {
            let dataId = (that.shopDetail.farmLabelDataVOS || []).map((e) => e.labelName)
            let arr = result.data.map((e) => {
              return {
                ...e,
                name: e.labelName,
                value: e.id,
                select: dataId.indexOf(e.labelName) > -1,
              }
            })
            this.typeArrData = arr
            resolve(arr)
          }
        )
      })
    },
    getInit() {
      getAction(`/api/market/farmCountryImpressDetail/list?impressId=${this.idData}`).then((result) => {
        this.detail.farmCountryImpressDetailVOS = result.data
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '标题',
            type: 'input',
            cols: 24,
            key: 'name',
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    renderTableTop() {
      /**
         * <a-button
            ghost
            type="danger"
            icon="delete"
            style={{ marginRight: '20px' }}
          >
            删除线路
          </a-button>
         */
      return (
        <div style={{ textAlign: 'right' }}>
          <a-dropdown>
            <a-button ghost type="primary">
              +添加旅游点
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item onClick={() => this.onCustom()}>自定义</a-menu-item>
              <a-menu-item onClick={() => this.onShowDown()}>店铺</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      )
    },
    getForm2() {
      let _this = this
      return {
        type: 'table',
        showFootButton: false,
        header: this.renderTableTop,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'a1',
            align: 'left',
            title: '拖动排序',
            width: 60,
            customRender: function (text, records) {
              return <a-icon type="menu" />
            },
          },
          {
            dataIndex: 'activeType',
            align: 'left',
            title: '类型',
            width: '15%',
            customRender: function (text, records) {
              if (records.activeType == null) {
                return '自定义'
              } else {
                return _this.activeTypeObj[records.activeType]
              }
            },
          },
          {
            dataIndex: 'imgUrl',
            align: 'left',
            title: '图片',
            width: '15%',
            type: 'image',
          },
          {
            dataIndex: 'a11',
            align: 'left',
            title: '简介',
            width: 200,
            customRender: function (text, records) {
              return (
                <div
                  class="description_box"
                  domProps={{
                    innerHTML: records.description,
                  }}
                ></div>
              )
            },
          },
          {
            dataIndex: 'shopName',
            align: 'left',
            title: '商家信息',
            width: '15%',
          },
          {
            dataIndex: 'farmLabelDataVOS',
            align: 'center',
            title: '显示标签',
            width: '15%',
            type: 'tagGroup',
            getData: (records) => (records.farmLabelDataVOS || []).map((e) => e.labelName),
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 180,
            title: '操作',
            type: 'buttonGroup',
            typeData: function ({ text, records, index }) {
              return [
                {
                  name: '编辑',
                  onClick: () => {
                    records.activeType == null
                      ? _this.onCustom(records, records.activeType)
                      : _this.onShowDown(records, records.activeType)
                  },
                },
                {
                  name: '删除',
                  type: 'pop',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    postAction(`/market/farmCountryImpressDetail/delete?id=${records.id}`).then((e) => {
                      if (e.code == 200) {
                        _this.getInit()
                        Message.success('删除成功')
                      } else {
                        Message.error(e.msg)
                      }
                    })
                  },
                },
              ]
            },
          },
        ],
        dataSource: this.detail.farmCountryImpressDetailVOS,
      }
    },
    getFormBanner() {
      let that = this
      return {
        title: 'Banner',
        type: 'cardForm',
        data: [
          {
            type: 'iconUpload',
            key: 'a12',
            typeData: [
              {
                maxLength: 1,
                key: 'image2',
                desc: '@2x   xhdpi <br/> 上传尺寸750*480px',
              },
            ],
            rules: [
              {
                // eslint-disable-next-line max-params
                validator(rule, value, callback, source, options) {
                  if (!that.detail.image2) {
                    return false
                  } else {
                    return true
                  }
                },
                message: '请选择图片上传',
              },
            ],
          },
        ],
      }
    },
    onCustom(formData = {}, activeType = null) {
      let formDatacopy = JSON.parse(JSON.stringify(formData))
      apiTool.showDrawer({
        title: '自定义点',
        width: '600px',
        form: [
          {
            name: '自定义图片（宽=750  高<=1334PX）限制3张',
            type: 'upload',
            maxLength: 3,
            key: 'imgUrl',
          },
          {
            name: '自定义内容',
            type: 'rich',
            key: 'description',
          },
        ],
        formData: formDatacopy,
        success: (form) => this.toSendData(form, activeType),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    reset() {
      this.shopDetail = {
        activeType: '',
        shopId: '',
        description: '',
        shopName: '',
        imgUrl: '',
        shopLabel: [],
      }
    },
    // 店铺弹出table
    renderOverlay({ setShow } = {}) {
      let that = this
      return (
        <div style={{ background: 'white', border: '1px solid #333333' }}>
          <Table
            dataSource={this.mainBodyTable}
            customRow={(record) => {
              return {
                on: {
                  // 事件
                  click: (event) => {
                    that.shopDetail.shopName = record.shopName
                    that.shopDetail.shopId = record.shopId
                    that.shopDetail.description = record.description
                    that.shopDetail.imgUrl = record.imgUrl
                    that.shopDetail.latitude = record.latitude
                    that.shopDetail.longitude = record.longitude
                    that.getTypeDdata(1)
                    setShow(false)
                  },
                },
              }
            }}
            columns={[
              {
                dataIndex: 'shopName',
                title: '店铺名称',
                dislapy: true,
              },
              {
                dataIndex: 'num',
                title: '农家乐(数量)',
                dislapy: true,
              },
              {
                dataIndex: 'distance',
                title: '距离线路中心点',
                dislapy: that.shopDetail.latitude && that.shopDetail.longitude,
              },
            ].filter((e) => e.dislapy)}
          />
        </div>
      )
    },
    onSearchDianpu(value = '') {
      api.command.searchPage
        .call(this, {
          url: `/api/commodity/farmMainBody/getMainBodyForImpress?impressId=${this.idData}&shopName=${value}&typeCode=${this.shopDetail.activeType}`,
        })
        .then((data) => {
          this.mainBodyTable = data.map((el) => {
            return {
              ...el,
              shopName: el.mainBodyName,
              foodDriveLabel: el.foodDriveLabel,
              distance: el.distance,
              shopId: el.id,
              farmLabelDataVOS: el.farmLabelDataVOS,
              description: el.content,
              imgUrl: el.bannerUrl,
              latitude: el.latitude,
              longitude: el.longitude,
            }
          })
          this.$forceUpdate()
        })
    },
    onOpen(typeArrData = this.typeArrData, activeType = this.activeType) {
      let that = this
      //   const newTypeData = typeArrData.map(e => {
      //     if (that.shopDetail.shopLabel.indexOf(String(e.value)) > -1) {
      //       return { ...e, select: true }
      //     } else {
      //       return { ...e, select: false }
      //     }
      //   })
      apiTool.showDrawer({
        title: '添加店铺',
        width: '600px',
        formData: this.shopDetail,
        typeData() {
          return {
            shopLabel: that.typeArrData,
            // shopLabel: newTypeData
          }
        },
        form: [
          {
            name: '类型',
            type: 'select',
            key: 'activeType',
            typeData: [
              {
                name: '农家乐',
                value: '3',
              },
              {
                name: '民宿农舍',
                value: '2',
              },
              {
                name: '农事体验-采摘',
                value: '4',
              },
              {
                name: '农事体验-垂钓',
                value: '5',
              },
              {
                name: '景点',
                value: '1',
              },
            ],
            defaultValue: '1',
          },
          {
            name: '店铺',
            type: 'dropDownInput',
            cols: 24,
            key: 'shopName',
            valueKey: 'shopId',
            renderOverlay: this.renderOverlay,
            inputProps: {
              type: 'InputSearch',
              addonBefore: () => {
                return <a-icon type="search" />
              },
              placeholder: '搜索店铺',
            },
            onInputSearch: this.onSearchDianpu,
          },
          {
            type: 'labelGroup',
            name: '店铺显示标签（4个内）',
            key: 'shopLabel',
          },
          {
            name: '店铺简介（选择店铺后自动获取，纯文本）',
            type: 'textArea',
            key: 'description',
          },
        ],
        success: (form) => this.toSendData(form, activeType),
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    onShowDown(formData = this.shopDetail, activeType = null) {
      this.reset()
      this.activeType = activeType
      this.shopDetail = JSON.parse(JSON.stringify(formData))
      let { shopLabel } = this.shopDetail
      if (typeof shopLabel !== Array) {
        this.shopDetail.shopLabel = shopLabel.length > 0 ? shopLabel.split(',') : []
      }
      if (this.shopDetail.shopId && this.shopDetail.activeType) {
        this.getTypeDdata().then((result) => {
          this.onOpen(result, activeType)
        })
      } else {
        this.onOpen([], activeType)
      }
    },
    toSendData(form, activeType) {
      const { data, setHidden } = form

      if (data.activeType) {
        if (!data.shopId) {
          this.$message.warning('请正确输入店铺！')
          return false
        }
      }

      let dataStr = ''
      if (data.activeType != null) {
        let a = data.shopLabel.filter((e) => e.select == true).map((e) => e.value)
        dataStr = a.toString()
      }
      api.command[data.id ? 'editPost' : 'create']
        .call(this, {
          url: `/market/farmCountryImpressDetail/${data.id ? 'update' : 'add'}`,
          params: {
            ...data,
            impressType: activeType == null ? '1' : '2',
            impressId: this.idData,
            shopLabel: dataStr,
          },
          isPost: false,
        })
        .then(() => {
          setHidden()
          this.reset()
          this.getInit()
        })
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            api.command.editPost
              .call(this, {
                url: '/market/farmCountryImpress/update',
                params: {
                  ...this.detail,
                  id: this.idData,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          onClick: () => {
            this.$router.push('/marketingCenter/rural')
          },
        },
      ]

      const right = [
        {
          name: '删除',
          type: 'danger',
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/market/farmCountryImpress/delete?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/rural')
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          shopName: this.mainBodyTable,
        }}
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getFormBanner(), this.getForm2()]}
        havedrag={true}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
/deep/ .ant-table-content {
  padding: 0 20px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_box {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 25px;
  max-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
